import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => {

    const data = useStaticQuery(graphql`
        query SiteQuery {
          allFile {
            totalCount
            edges {
              node {
                relativePath
                size
                extension
                birthTime
              }
            }
          }
        }
    `);

    return (
        <Layout>
            <SEO title="Page three"/>
            <h1>Hi from the third page</h1>

            <table>
                <thead>
                <tr>
                    <th>Path</th>
                    <th>Size</th>
                    <th>Ext</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {data.allFile.edges.map(({ node }, index) => (
                    <tr key={index}>
                        <td>{node.relativePath}</td>
                        <td>{node.size}</td>
                        <td>{node.extension}</td>
                        <td>{node.birthTime}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <p>Content</p>
            <Link to="/page-2">page 2</Link>
        </Layout>
    )
};
